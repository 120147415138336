.standard_modal_close_button{
    display: block;
    width: fit-content;
    margin: auto;
}

.standard_modal_message{
    padding: 10px;
    margin-bottom: 10px;
}
