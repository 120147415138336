$blue: #003399;

.navbar_section{
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.school_summary_text {
    flex-basis: 100%;
    text-align: left;
}

.progress_circle_text{
    flex-basis: 100%;
    text-align: center;
    margin: 8px;
}

.school_achievements, .school_achievements_circles{
    display: flex;
    flex-direction: row;
}

.school_achievements_circles{
    width: 50%;
}

.progress_circle{
    width: 50%;
    text-align: center;

    svg{
        width: 100%;
    }
}

.progress_bar{
    width: 50%;
    text-align: center;
    margin-top: 50px;
}

.margin_border{
    margin-top: 10px;
    margin-bottom: 10px;
}

.qr_code{
    width: 50%;
    text-align: center;
}

.qr_code_text{
    width: 50%;
    text-align: left;

}

.school_qr_code{
    display: flex;
    flex-direction: row;
}

.share-page-qr-container-nuclavis{
    border: 2px solid black;
    border-radius: 8px;
    width: 75%;
    margin: 0 auto;
}

.second_level{
    display: flex;
    flex-direction: row;
}

.section_qr_code{
    width: 50%;
    text-align: center;
    margin-right: 10px;
}

.section_message{
    width: 50%;
    text-align: center;
    margin-left: 10px;
}

.qr_code_heading{
    text-align: left;
}

.message_heading{
    text-align: left;
}

.create_message_button{
    margin-top: 25px;
}

.progress_amounts{
    display: flex;
    flex-direction: row;
    max-width: 42%;
    overflow: auto;
}

.progress_percent{
    width: 16%;
}

.progress_data{
    display: flex;
    justify-content: space-between;
    margin-right: 25px;
    margin-left: 25px;
}

.red_text_button {
    color: #cd252b;
    background: none;
    border: none;
    padding: 10px;
    min-width: 100px;
    text-decoration: underline;
}

.edit_school_goal_button{
    text-align: right;
}

.progress_amounts_text{
    margin: 1px;
}

.edit_school_goal_button{
    cursor: pointer;
}

.edit_modal_dialog{
    min-width: 50vw;
    max-width: 80vw;
    margin-top:100px;
}

.manage_badges_heading{
    font-size: 16px;
    color: $blue;
    width: 100%;
}

#edit_goal_modal_input{
    border-radius: 0px 5px 5px 0px !important;
    border-color: $blue;
}

.dollar_input_symbol{
    width: 35px;
    background-color: rgb(239, 239, 239);
    text-align: center;
    height: 35px;
    border-radius: 5px 0px 0px 5px;
    border: solid lightgray 1px;
    display: flex;
    align-items: center;
    justify-content: center;

    p{
        margin: 0px;
    }
}

.school_edit_modal_input_row{
    margin-bottom: 15px;
    border-top: 1px #d8d8d8 solid;
    padding-top: 15px;
    border-bottom: 1px #d8d8d8 solid;
    padding-bottom: 15px;
}

.modal_save_button{
    margin-left: auto;
}

#edit_message_modal_input{
    width: 100%;
    resize: none;
    font-family: inherit;
}

#email-team-page-btn-nuclavis, #save-team-page-btn-nuclavis{
    cursor: pointer;
}

//Small Screens
@media only screen and (max-width: 850px) {
    .school_achievements{
        flex-direction: column;
    }

    .school_achievements_circles{
        width: 100%;
    }
    
    .progress_bar, .progress_circle{
        width: 100%;
        margin-top: 5px;
    }

    .progress_circle svg{
        width: 60%;
        margin-top: 5px;
    }

    .progress_bar_first_child{
        height: 35px !important;
    }
    
    .progress_data{
        margin-left: 10px;
        margin-right: 10px;
        font-size: 90%;
    }
}

@media only screen and (max-width: 720px) {
    .second_level{
        flex-direction: column;
        .section_qr_code, .section_message {
            width: calc(100% - 30px);
            margin-left: 0px;
            margin-right: 0px;
        }
    }

    .progress_bar_first_child{
        height: 20px !important;
    }
}

@media only screen and (max-width: 450px) {    
    .school_achievements_circles{
        flex-direction: column;
    }
    
    .progress_data{
        margin-left: 10px;
        margin-right: 10px;
        font-size: 70%;
    }
}