/* Colors */
$red:rgb(182, 0, 0);
$blue: rgb(0, 51, 153);

.login_page{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 12vw;
    margin-right: 12vw;
}

.login_page_heading{
    color: #fff;
    font-size: 36px;
    font-weight: bold;
    margin-right: auto;
    text-shadow: 2px 2px 1px black;
}

.login_page_content{
    background: #fff;   
    width: 100%;
}

.login_page_details{
    margin: 8vw 8vw 11vw 8vw;
    padding: 8vw;
}

.login_button{
    margin-top: 20px;
    width:100%;
}

.login_error{
    color: $red;
    font-size:14px;
    margin-top: 10px;
}