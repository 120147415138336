$black: #000;
$white: #fff;
$red: #cd252b;
$blue: #003399;
$light_gray: #ccced0;
$light_blue: #cee3f1;
$lighter_gray: #d6d7d8;
$highlight_blue: #0875d4;
$gray: #707070;


$navbarHeight: 40px;

.navbar_section{
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    background: #ffffff;
}

.navbar_logo{
    margin-left: 100px;
    height: 12vh;
    padding: 15px;
}

nav{
    background: #ffffff;
    border-top: solid 1px lightgray;
    width: 100%;
}

.navbar_items{
    display: flex;
    justify-content: space-between;
    margin: 0px 100px;
    height: $navbarHeight;
    @media screen and (max-width: 1000px) {
       display: none;
    }
}

.navbar_link{
    color: $red;
    text-decoration: none;
    font-weight: bold;
    cursor: pointer;
    font-size: 16px;
    height: $navbarHeight;
    line-height: $navbarHeight;
    margin: 0px 20px;
    
    &.active {
        border-bottom: 5px solid $red;
        height: 35px;
    }
}

.navbar_link_button{
    color: #ffffff;
    background: $red;
    font-size: 14px;
    width: 100px;
    text-align: center;
}

.navbar_link_active{
    border: 15px solid $red;
}

.navbar_link_name{
    display: inline;
    flex-grow: 1;
    text-align: right;
    font-size: 12px;
}

.navbar_mobile{
    background-color: white;
    height: $navbarHeight;
    @media screen and (min-width: 1001px) {
       display: none;
    }
    margin-right: 15px;
}

.navbar_items_mobile{
    position: relative;
    z-index: 1;
    border: 3px solid green;
    display: flex;
    flex-direction: column;
    background-color: white;
    border: 1px solid #ccced0;
    box-shadow: 0px 0px 2px #ccced0;
    border-radius: 0px 0px 5px 5px;
}

.navbar_link_mobile{
    color: $red;
    text-decoration: none;
    padding: 5px 10px;
    font-weight: bold;
    cursor: pointer;
    font-size: 16px;
    border: none;
    background: $white;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding:10px;

    &.active {
        text-decoration: underline;
        &.single {
            background: $red;
            color: $white;
            text-decoration: none;
        }
    }
}

.navbar_link_mobile > svg > path{
    fill: $red
}

.navbar_subsection_mobile{
    display: flex;
    flex-direction: column;
}

.navbar_sublink_mobile{
    padding: 5px 10px;
    padding-left: 30px;
    text-decoration: none;
    font-weight: bold;
    font-size: 14px;
    color: $blue;

    &.active {
        background:  $red;
        color: $white;
    }
}

.navber_mobile_heading{
    display: flex;
    align-items: center;
}

.navbar_link_name_mobile{
    display: inline;
    flex-grow: 1;
    text-align: left;
    height: $navbarHeight;
    line-height: $navbarHeight;
    font-size: 14px;
    font-weight: bold;
    margin-left: 15px;
    color: $red;
}

.navbar_link_button_mobile{
    color: #ffffff;
    background: $red;
    font-size: 14px;
    width: 100px;
    text-align: center;
    line-height: $navbarHeight;
    display: block;
    font-weight: bold;
    text-decoration: none;
    cursor: pointer;
    height: 40px;
    margin: 0px 10px;
}

.navbar_mobile_menu_button_container{
    padding: 5px;
    width: 30px;
    margin: auto;
    display: flex;
    margin-right: 5px;
    
}

.navbar_mobile_menu_button_container > svg {
    margin: auto;
}

.navbar_mobile_menu_button_container > svg > path {
    fill: $red;
}