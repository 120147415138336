$black: #000;
$white: #fff;
$red: #cd252b;
$blue: #003399;
$light_gray: #ccced0;
$light_blue: #cee3f1;
$lighter_gray: #d6d7d8;
$highlight_blue: #0875d4;
$gray: #707070;
 
.global_message_subtext{
    margin-top: 10px;
    margin-bottom: 40px;
}

.global_message_inputs{
    display: flex;
    gap: 20px;
    padding: 10px 0px;
    flex-wrap: wrap;
    justify-content: space-between;
}

.global_message_buttons{
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
}

.add_global_message_dialog{
    max-width: 80vw;
}

.global_message_modal_message{
    font-size: 14px;
    margin-top: 15px;
}

.global_message_new_input{
    width: 99%;
    display: block;
    margin: auto;
    resize: none;
    border: 1px solid $blue;
    border-radius: 5px;
}

.global_message_textbox_container{
   margin-top: 5px;
}

.global_message_modal_close_button{
    margin-top: 15px;
}

.global_message_active{
    color: $red;
    font-weight: bold;
}
