$black: #000;
$white: #fff;
$red: #cd252b;
$blue: #003399;
$light_gray: #ccced0;
$light_blue: #cee3f1;
$lighter_gray: #d6d7d8;
$highlight_blue: #0875d4;
$gray: #707070;

.manage_badges_heading{
    font-size: 14px;
    color: $blue;
    width: 100%;
}

.manage_badges_section{
    display: flex;
    gap: 25px;
    max-width: 60vw;
    flex-wrap: wrap;
    justify-content: center;
    padding: 15px;
}

.manage_badges_item{
    text-align: center;
    width: 120px;
}

.manage_badges_image{
    width: 100px;
}

#manage_badges_close_btn{
    margin: auto;
    display: block;
    margin-top: 20px;
}

.manage_badges_checkbox_container{
    padding: 0px;
    width: 15px;
    margin: auto;
    margin-top: 5px;
}

.manage_badges_item_label{
    font-size: 12px;
    font-weight: bold;
    margin-top: 5px;
}