.resend_email_form_section_item{
    flex-basis: 48%;
    margin: 5px 10px;
    @media screen and (max-width: 800px) {
        flex-basis: 100%
     }

     label{
        width: 100%;
        display: inline-block;
     }
}

.resend_email_form_section{
    display: flex; 
    align-items: center;
}

.resend_email_modal_dialog{
    width: 80%;
    max-width: 800px;
}

.resend_email_buttons{
    display: flex;
    gap: 20px;
    margin-top: 10px;
}

.resend_email_modal_resending_text{
    margin-left: 20px;
    font-size: 80%;
    font-style: italic;
}