$black: #000;
$white: #fff;
$red: #cd252b;
$blue: #003399;
$light_gray: #ccced0;
$light_blue: #cee3f1;
$lighter_gray: #d6d7d8;
$highlight_blue: #0875d4;
$gray: #707070;

.pagination_section {
    margin-top: 10px;
    margin-left: auto;
    width: fit-content;
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
}

.pagination_button{
    color: $blue;
    background: $light_blue;
    border: none;
    border-radius: 3px;
    border: none;
    padding: 5px 7px;
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
}

.pagination_button_active {
    background: $blue;
    color: $white;
}

.pagination_button_left {
    padding: 5px 6px 5px 5px;
}

.pagination_button_right {
    padding: 5px 5px 5px 6px;
}

.pagination_button > svg > path {
    fill: $blue;
}
  