$black: #000;
$white: #fff;
$red: #cd252b;
$blue: #003399;
$light_gray: #ccced0;
$light_blue: #cee3f1;
$lighter_gray: #d6d7d8;
$highlight_blue: #0875d4;
$gray: #707070;
$light_blue:#cee4f1;

//HEADINGS
.small_heading{
    color: $blue;
    font-size: 18px;
    font-weight: bold;
}

//DISPLAY STATES
.displayBlock{
    display: block;
}

.displayFlex{
    display: flex;
}

.displayHidden{
    display: None;
}

//SECTIONS
.section_border {
    border: 1px solid $light_gray;
    border-radius: 8px;
    padding: 15px;
}

.flex_section{
    display: flex;
    flex-wrap: wrap;
}

.flex-column-reverse {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

//BUTTONS
.red_button{
    color: #ffffff;
    background: $red;
    font-weight: bold;
    border: none;
    padding: 10px;
    min-width: 100px;
    cursor: pointer;
}

.red_outline_button{
    background: #ffffff;
    color: $red;
    font-weight: bold;
    border: 2px solid $red;
    padding: 8px;
    min-width: 100px;
    cursor: pointer;
}

.blue_text_button{
    color: $blue;
    background: none;
    border: none;
    padding: 10px;
    min-width: 100px;
    text-decoration: underline;
    cursor: pointer;
}

//INPUTS
select {
    background: $white;
    border-radius: 5px;
    border: 1px solid $black;
    color: black;

    display: block;
    font-size: 14px;
    padding: 8px;
    padding-right: 30px;

    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url('data:image/svg+xml;utf8, <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="%23003399" viewBox="-20 -22.095 39.98 22.01" stroke-width="1550"> <path stroke-width="1400" d="M -20 -19 L -15 -25 L -0.02 -11.084 L 15 -25 L 20 -19 L -0.02 -0.084 Z"/></svg>');
    background-repeat: no-repeat;
    background-position-x: calc(100% - 10px);
    background-position-y: 11px;
    width: 100%;
}

label {
    font-weight: 500;
    font-size: 15px;
    padding: 3px 0px;
}

input {
    background: $white;
    border-radius: 5px;
    border: 1px solid $black;
    color: black;
    display: block;
    font-size: 14px;
    padding: 8px;
    width: 100%;
    margin: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -o-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;  
}


.search_input {
    border: 1px solid $black;
    border-radius: 5px;
    font-size: 14px;
    background: $white;
    padding: 10px;
    padding-right: 30px;
    padding-left: 12px;
  
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url('data:image/svg+xml;utf8, <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="%23003399" viewBox="-0.00805664 0.138748 497.2 500.4" stroke-width="2550"> <path stroke-width="2400" d="M 493 448 L 383 335 C 403.4 307.896 421.2 261.396 419 212 C 421.2 94.496 330 3 210.6 0.196 S -1 98 0 210.796 S 78 420 210.6 421.396 C 261.4 421.396 308 403.396 339 385 L 444 497 C 464 506 476 496 486 488 C 501 475 497 463 496 455 Z M 69 213 C 71 132 130 68 210 68 S 350 130 352 215 S 286 351 211 351 S 73 292 70 213"/></svg>');
    background-repeat: no-repeat;
    background-position-x: calc(100% - 10px);
    background-position-y: 11px;
    width: auto;
  }


.checkbox_container {
    display: block;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: 14px;
}
  
  .checkbox_container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  input[type='checkbox']:focus + span {
    outline: 5px auto -webkit-focus-ring-color;
  }
  
  .checkbox_checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 15px;
    width: 15px;
    background-color: #ffffff;
    border: 1px solid #383f80;
    border-radius: 5px;
  }
  
  .checkbox_checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }
  
  .checkbox_container
    input:checked
    ~ .checkbox_checkmark:after {
    display: block;
  }
  
  .checkbox_container .checkbox_checkmark:after {
    left: 3px;
    top: 0px;
    width: 5px;
    height: 10px;
    border: solid $red;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

//TABLE
.table_container{
    position: relative;
    overflow-x: auto;
}

table {
    border-collapse: collapse;
    border-spacing: 0px;
    width: 100%;
    font-size: 14px;
    margin-top: 5px;
    text-align: left;
}
  
table > thead > tr > th {
    border: 1px solid $light_gray;
    color: $red;
    border-bottom: 3px solid $blue;
    font-weight: bold;
    padding: 5px;
    text-align: left;
    background-color: white;
    line-height: normal;
}
  
table > tbody > tr > td {
    border: 1px solid $lighter_gray;
    padding: 7px;
}
  
table > tbody > tr:nth-child(odd) {
    background-color: $light_gray;
}

.report_no_results{
    background: $white;
    text-align: center;
}

//ERRORS
.small_error_text {
    color: $red;
    font-size: 14px;
}

.required_error_message{
    margin-left: auto;
    font-style: italic;
    font-size: 80%;
    float: right;
}
  

//Report Search Sections
.search_section_info_text {
    flex-basis: 100%;
}

.search_section_row{
    margin-top: 15px;
    gap: 15px;
}

.search_section_item{
    flex-basis: 23%;
    @media screen and (max-width: 1000px) {
        flex-basis: 100%
     }
}

.search_section_item_double{
    flex-basis: 47%;
}

.search_section_double_items{
    display: flex;
    gap: 15px;
    margin-top: 5px;
}

.search_section_double_item{
    display: flex;
    gap: 5px;
    align-items: center;
}

.search_section_double_item > label {
    font-size: 12px;
    min-width: 60px;
}

.search_section_double_item > input {
    @media screen and (max-width:1000px){
        width: auto;
        max-width: 135px;
    }
}

.search_section_buttons_row{
    justify-content: space-between;
    align-items: center;
}

.search_section_buttons_row_right{
    justify-content: flex-end;
}

.select_columns_checkbox_item {
    text-align: left;
    margin: 5px;
    margin-right: 10px;
    .checkbox_label_text{
        margin-top: -3px;
    }
}

.search_section_error_text{
    color: $red;
    font-size: 14px;
    font-style: italic;
}

.search_section_search_buttons{
    display: flex;
    gap: 20px;
}

.search_section_table_heading_row{
    align-items: flex-end;
    margin-top: 45px;
    gap: 25px;
    padding: 5px;
}

.search_section_table_heading_label{
    flex: 1
}

.search_section_table_buttons{
    display: flex;
    gap: 20px;
}

//SELECT COLUMNS
.select_columns_section{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 275px;
}

@media only screen and (max-width: 775px) {
    .select_columns_section{
      overflow-y: auto;
      min-height: 275px;
      height: auto;
      max-height: 400px;
      flex-wrap: nowrap;
    }
}

.modal_title{
    margin: 0px;
    width: 100%;
}

.modal_title_small{
    font-size: 20px;
    color: $blue
}

#school_title{
    color: $white;
    font-size: 34px;
    font-weight: bold;
    margin-left: 35px;
}

//REQUIRED

.required_text{
    color: $red;
    font-style: italic;
    font-size: 12px;
    display: none;
    text-align: right;
    margin-bottom: 2px;
}

.required > .required_text{
    display: block;
}

.required > textarea{
   border: 1px solid $red;
}


//banner message
.general_banner{
    background: $light_blue;
    color: $blue;
    display: flex;
    padding: 10px;
    margin: 10px 0px;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    border: 1px solid $light_gray;
    gap: 15px;
}

.general_banner_message{
    font-size: 18px;
    font-weight: bold;
    line-height: 1.3;
}