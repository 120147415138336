
/* Colors */
$red:rgb(182, 0, 0);
$blue: #003399;

/*Visibility*/
.hidden{
  display: none !important;
}

.invisible{
  opacity: 0 !important;
}

.visible{
  opacity: 1 !important;
}

.main_layout {
  background-image: url('../src/images/background_image.png');
  background-size: cover;
  min-height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100vw;
  padding-bottom: 12vw;
}


//PAGE LAYOUT
.page_container{
  display: flex;
  justify-content: center;
  padding: 25px;
}

.page_content{
  background-color: #ffffff;
  height: 100%;
  min-width: 200px;
  padding: 10px 25px;
  margin: 10px 10px 10px 0;
  flex: 1 0 0%;
  min-height: 400px;
}

.page_heading{
  color: $blue;
}

.menu_content{
  margin: 10px 0 10px 10px;
  max-width: calc(3.5rem + 9rem);
}

.side_bar{
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 1000px) {
    display: none;
  }
}

//MODAL
#modal_background{
  width: 100vw;
  height: 100%;
  height: -moz-available;          /* WebKit-based browsers will ignore this. */
  height: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
  height: stretch;
  left:0;
  z-index:1500;
  background-color: rgba(0, 0, 0, 0.705);
  position: absolute;
  display: flex;
  justify-content: center;
  &no-back{
    background-color: rgba(0, 0, 0, 0);
  }
}
