$black: #000;
$white: #fff;
$red: #cd252b;
$blue: #003399;
$light_gray: #ccced0;
$light_blue: #cee3f1;
$lighter_gray: #d6d7d8;
$highlight_blue: #0875d4;
$gray: #707070;
$dark_gray: #6C757D;

.table_sort_section {
    display: block;
    margin-left: auto;
    align-self: flex-end;
}
  
.table_sort_asc {
    color: $dark_gray;
    margin-bottom: -8px;
    cursor: pointer;
}

.table_sort_active {
    color: $red !important;
    display: block;
}
  
.table_sort_desc {
    color: $dark_gray;
    cursor: pointer;
}

.table_column_header_button {
    display: flex;
    flex-direction: row;
    align-items: center;
}
  
  