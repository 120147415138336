$black: #000;
$white: #fff;
$red: #cd252b;
$blue: #003399;
$light_gray: #ccced0;
$light_blue: #cee3f1;
$lighter_gray: #d6d7d8;
$highlight_blue: #0875d4;
$gray: #707070;


.modal_dialog{
    background: $white;
    padding: 15px;
    border-radius: 5px;
    border: none;
    height: fit-content;
}

.modal_dialog_close {
    color: $blue;
    width: 40px;
    // position: absolute;
    right: 10px;
    top: 10px;
    z-index: 10;
    background: $white;
    border: none;
    cursor: pointer;
  }