
.navbar_section{
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.nclvs_smt_pr_input_date_label{
    min-width: 90px;
    flex-wrap: nowrap;
}

.nclvs_smt_pr_text_input{
    width: 135px;
}

@media only screen and (max-width: 400px){
    .nclvs_smt_pr_input_date_label{
        min-width: 0px;
        flex-wrap: wrap;
    }
 }