$black: #000;
$white: #fff;
$red: #cd252b;
$blue: #003399;
$light_gray: #ccced0;
$light_blue: #cee3f1;
$lighter_gray: #d6d7d8;
$highlight_blue: #0875d4;
$gray: #707070;

.table_page_size_row{
    font-size: 12px;
    display: flex;
    justify-content: flex-end;
    gap: 1px;
    margin-top: 10px;
    margin-bottom: 0px;
}

.table_page_size_button{
    border: none;
    background: none;
    font-size: 12px;
    color: $blue;
    text-decoration: underline;
    cursor: pointer;
}

.table_page_size_button_active{
    color: $red;
    font-weight: bold;
    text-decoration: none;
}

.table_page_show_label{
    color: $red;
}
