$blue: #003399;

.sidebar_link {
    background-color: #cee4f1;
    width: 165px;
    padding: 10px 15px;
    text-decoration: none;
    margin: 0 0 2px 2px;
    color: $blue;
    font-weight: 600;

     
    &.active {
        background-color: #fff;
        width: 178px;
    }
}